import React from "react";
import {useSelector} from "react-redux";
// UI
import {
  Typography,
  Divider,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import useProductPanelStyles from "styles/useProductPanelStyles";
import {ReactComponent as DescriptionIcon} from "assets/icons/description.svg";
import {ReactComponent as ListIcon} from "assets/icons/list.svg";
// Custom
import Conditions from "components/Panels/Experience/ExperienceContent/Conditions/Conditions";
import ConnectedToButton from "core/buttons/ConnectedToButton.jsx";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import FilledTextField from "core/inputs/FilledTextField";
import AccordionItem from "core/listItems/AccordionItem";
import DateTimePicker from "core/inputs/DateTimePicker";
import ElapsedTime from "components/TextFields/ElapsedTime";
import Payment from "components/Panels/Booking/Payment";
import PrimaryButton from "core/buttons/PrimaryButton";
import EmailEditor from "components/Misc/EmailEditor";
import CustomSwitch from "core/switches/CustomSwitch";
import FilledSelect from "core/selects/FilledSelect";
import CustomDialog from "core/dialogs/CustomDialog";
import InfoText from "components/Misc/InfoText";
// Utils
import {
  contentTypeEnum,
  productsFees,
  productsServices,
  productsUpsells,
  templateImages,
} from "configuration/enums.js";
import {capitalize, newUuid} from "utilities/helperFunctions";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import {THEME} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import infoTexts from "assets/infoTexts";
import {isValid} from "date-fns";
import clsx from "clsx";
import _ from "lodash";
import PreviewBPPanel from "components/Panels/PreviewBPPanel";

const characterLimit = 60;
const PRICING_MODELS = [
  {name: "Flat fee", value: "1", label: "flat_fee"},
  {name: "Per night", value: "<num_nights>", label: "per_night"},
  {name: "Per person", value: "<num_adult>", label: "per_person"},
  {
    name: "Per person per night",
    value: "<num_adult> * <num_nights>",
    label: "per_person_per_night",
  },
  {name: "Per pet", value: "<num_pets>", label: "Per pet"},
  {
    name: "Per pet per night",
    value: "<num_pets> * <num_nights>",
    label: "per_pet_per_night",
  },
];

const ADVANCED_PRICING_MODELS = [
  {name: "Per child", value: "<num_child>", label: "per_child"},
  {
    name: "% of nightly rate",
    value: "{input_value} * <booking_nightly_rate>",
    label: "percentage_nightly_rate",
  },
  {
    name: "% of nightly rate + flat fee",
    value: "{input_value} * <booking_nightly_rate> + {input_value_2}",
    label: "percentage_nightly_rate_flat_fee",
  },
  {
    name: "% of total booking amount",
    value: "{input_value} * <total_guest_paid>",
    label: "percentage_total_booking_amount",
  },
  {
    name: "% of payment amount remaining",
    value: "{input_value} * <booking_payment_amount_remaining>",
    label: "percentage_payment_amount_remaining",
  },
];

const ProductModal = ({
  open,
  listing_id,
  group_id,
  selectedGroup,
  listing,
  isGroupView,
  selected,
  editData,
  handleClose,
  handleSave,
  disabled,
  skus,
  setSkus,
  content,
  setContent,
  langs,
  setLangs,
  selectedLang,
  setSelectedLang,
  headerImage,
  setHeaderImage,
  getUpdatedContent,
}) => {
  const flags = useFlags();
  const classes = useProductPanelStyles({dialogBGImage: headerImage});
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  // State
  const [listingsOpen, setListingsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(null);
  const [expandedPayout, setExpandedPayout] = React.useState(null);
  const [isValidTime, setIsValidTime] = React.useState({});
  const [externalListings, setExternalListings] = React.useState([]);
  const [isContentValid, setIsContentValid] = React.useState(true);
  // General
  const AIListingData = !!listing_id
    ? houses[listing_id]
    : houses[selectedGroup?.connected_to?.ids?.[0]];
  let isUpsell = !selected.is_fee;
  let skuKeys = Object.keys(skus);
  let hasManySkus = skuKeys.length > 1;
  let upsell_spec =
    selected.resource === "fee"
      ? productsFees[selected.properties.product_type]
      : selected.resource === "up"
        ? productsUpsells[selected.properties.product_type]
        : productsServices[selected.properties.product_type];
  let multiple_skus = skuKeys.length > 1;
  let nightlyRateOpt = ADVANCED_PRICING_MODELS.find(
    (apm) => apm.label === "percentage_nightly_rate_flat_fee",
  );
  let upsell_label = selected.is_fee
    ? selected.product_id === "deposit"
      ? "deposit"
      : "fee"
    : "upsell";
  const prevSelected = usePrevious(selected);
  const invalidTime = React.useMemo(
    () => !!Object.values(isValidTime).some((i) => !i),
    [isValidTime],
  );
  const invalidSkus = React.useMemo(() => {
    if (!skuKeys.length) {
      return true;
    } else if (skuKeys.length === 1) {
      return (
        !String(skus[skuKeys[0]].value) || Number(skus[skuKeys[0]].value) < 0
      );
    } else {
      const invalidSkuNames = Object.keys(content.skus).some((skID) =>
        Object.keys(content.skus[skID]).some((field) =>
          Object.keys(content.skus[skID][field]).some(
            (langKey) => !content.skus[skID][field][langKey]?.trim(),
          ),
        ),
      );
      const invalidSkuValues = skuKeys.some(
        (sk) => !String(skus[sk].value) || Number(skus[sk].value) < 0,
      );
      const skusWithFlatFee = skuKeys.filter(
        (sk) => nightlyRateOpt.value === skus[sk].unit,
      );
      const invalidSkuFlatFee = !!skusWithFlatFee.length
        ? skusWithFlatFee.some(
            (sk) =>
              !skus[sk].hasOwnProperty("value2") ||
              !String(skus[sk].value2) ||
              Number(skus[sk].value2) < 0,
          )
        : false;
      const invalidSkuTimes = ["checkin", "checkout"].includes(
        selected.properties.product_type,
      )
        ? skuKeys.some((sk) => {
            let time =
              skus[sk][
                selected.properties.product_type === "checkin"
                  ? "checkin_time"
                  : "checkout_time"
              ];
            return !time || time === "Invalid Date";
          })
        : false;
      return (
        invalidSkuNames ||
        invalidSkuValues ||
        invalidSkuFlatFee ||
        invalidSkuTimes
      );
    }
  }, [skus, content]);
  const invalidTitle = React.useMemo(() => {
    return Object.keys(content.name).some(
      (langKey) => !content.name[langKey]?.trim(),
    );
  }, [content, selectedLang]);
  let disableSave =
    !!disabled ||
    invalidTime ||
    invalidSkus ||
    invalidTitle ||
    loadingResource ||
    !isContentValid;

  React.useEffect(() => {
    if (!!expandedPayout) {
      setExpandedPayout((prev) => null);
    }
  }, [expanded]);

  React.useEffect(() => {
    if (
      !Object.keys(selected).length ||
      (!!prevSelected && !!Object.keys(prevSelected).length)
    ) {
      return;
    }
    setExternalListings((prev) =>
      !!selected?.connected_ids?.listing?.length &&
      selected?.connection_type === "ids"
        ? selected?.connected_ids?.listing
            .map((l) => l.replace("l#", ""))
            .filter(
              (l) => !(selectedGroup?.connected_to?.ids || []).includes(l),
            )
        : [],
    );
  }, [selected]);

  function handleEditProduct(key, newVal) {
    switch (key) {
      case "name":
        if (newVal.length > characterLimit) {
          newVal = newVal.substring(0, characterLimit);
        }
        const newNameArr = Object.keys(content.name).map((l) => ({
          language: l,
          value: l === selectedLang ? newVal : content.name[l],
        }));
        setContent((prev) => ({
          ...prev,
          name: {...prev.name, [selectedLang]: newVal},
        }));
        editData({
          ...selected,
          properties: {...selected.properties, name: newNameArr},
        });
        break;
      case "description":
        if (content.desc?.[selectedLang] === newVal) {
          return;
        }
        const newDescArr = Object.keys(content.desc).map((l) => ({
          language: l,
          value: l === selectedLang ? newVal : content.desc[l],
        }));
        setContent((prev) => ({
          ...prev,
          desc: {...prev.desc, [selectedLang]: newVal},
        }));
        editData({
          ...selected,
          properties: {...selected.properties, description: newDescArr},
        });
        break;

      default:
        editData({
          ...selected,
          properties: {...selected.properties, [key]: newVal},
        });
        break;
    }
  }

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  function handleEditSKU(sku, key, val) {
    setSkus({...skus, [sku.sku_id]: {...sku, [key]: val}});
  }

  function handleAddSKU() {
    let newSku = {
      value: 0,
      unit: "1",
      sku_id: `new_sku_${newUuid()}`,
      name: langs.map((l) => ({language: l, value: ""})),
    };
    let newSkusContent = {...content.skus};
    newSkusContent[newSku.sku_id] = {name: {}};
    _.each(langs, (l) => {
      newSkusContent[newSku.sku_id].name[l] = "";
    });
    setSkus((prev) => ({...prev, [newSku.sku_id]: newSku}));
    setContent((prev) => ({...prev, skus: newSkusContent}));
    setExpanded((prev) => newSku.sku_id);
  }

  function handleRemoveSKU(sku) {
    let newSkusContent = {...content.skus};
    let newSkus = {...skus};
    delete newSkusContent[sku.sku_id];
    delete newSkus[sku.sku_id];
    setSkus(newSkus);
    setContent((prev) => ({...prev, skus: newSkusContent}));
  }

  function handleEditPricingModel(sku, val) {
    if (val === "custom") {
      handleEditSKU(sku, "custom_pricing", true);
    } else {
      let selectedPricingModel =
        PRICING_MODELS.find((pm) => pm.value === val) ??
        ADVANCED_PRICING_MODELS.find((apm) => apm.value === val);
      if (!!selectedPricingModel && !!flags.customGapNightPrice) {
        handleEditSKU({...sku, label: selectedPricingModel.label}, "unit", val);
      } else {
        handleEditSKU(sku, "unit", val);
      }
    }
  }

  const handleDateChange = (sku, field) => (newDate) => {
    setIsValidTime((prev) => ({...prev, [field.path]: isValid(newDate)}));
    if (!newDate) {
      handleEditSKU(sku, field.path, "");
    } else {
      let options = {
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
      };
      const localTime = newDate.toLocaleTimeString([], options);
      handleEditSKU(sku, field.path, localTime);
    }
  };

  const handleNameChange = (sku) => (e) => {
    const val = e.target.value;
    const newSku = {...content.skus[sku.sku_id]};
    newSku.name[selectedLang] = val;
    setContent((prev) => ({
      ...prev,
      skus: {...prev.skus, [sku.sku_id]: newSku},
    }));
  };

  const handlePriceChange = (sku) => (e) => {
    const val = e.target.value;
    handleEditSKU(sku, "value", val);
  };

  const handleFlatFeeChange = (sku) => (e) => {
    const val = e.target.value;
    let newSku = sku.hasOwnProperty("value2") ? sku : {...sku, value2: ""};
    handleEditSKU(newSku, "value2", val);
  };

  const handleHeaderImgChange = (picture) => setHeaderImage(picture);

  function getField(field, sku) {
    switch (field.type) {
      case "time_offset":
        return (
          <ElapsedTime
            edit
            value={sku[field.path] ?? ""}
            label={"Time to next booking"}
            onValueChange={(e) =>
              handleEditSKU(sku, field.path, e.target.value)
            }
          />
        );
      case "text":
        return (
          <FilledTextField
            label={field.label}
            disabled={disabled}
            fullWidth
            value={sku[field.path] ?? ""}
            onChange={(e) => handleEditSKU(sku, field.path, e.target.value)}
          />
        );
      case "number":
        return (
          <FilledTextField
            type="number"
            label={field.label}
            disabled={disabled}
            fullWidth
            value={sku.properties?.[field.path] ?? ""}
            onChange={(e) =>
              handleEditSKU(sku, "properties", {[field.path]: e.target.value})
            }
          />
        );
      case "time":
        const dateValue = !!sku[field.path]
          ? new Date(`${new Date().toDateString()} ${sku[field.path]}`)
          : null;
        return (
          <DateTimePicker
            filled
            type="time"
            value={dateValue}
            label={field.label}
            onChange={handleDateChange(sku, field)}
          />
        );
      case "charge_card":
        return (
          sku.flex_product_id !== "fee" && (
            <div className={classes.collectDepositContainer}>
              <Typography variant="subtitle2">
                How would you like to collect your deposit?
              </Typography>
              <FormControl component="fieldset" disabled={disabled}>
                <RadioGroup
                  value={sku[field.path] ?? false}
                  onChange={(e) =>
                    handleEditSKU(sku, field.path, !sku[field.path])
                  }
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Place hold"
                    classes={{label: classes.radioLabel}}
                  />
                  <Typography className={classes.radioDescription}>
                    When your guests pays the deposit we'll ensure their card is
                    valid, but funds are only collected when you claim the
                    deposit. Unclaimed deposits are free & claimed deposits
                    incur a non-refundable 3% processing fee.
                  </Typography>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Charge card"
                    classes={{label: classes.radioLabel}}
                  />
                  <Typography className={classes.radioDescription}>
                    When your guest pays the deposit we'll collect funds
                    immediately and refund them one day after check-out. All
                    deposits must be refunded within 60 days & incur a
                    non-refundable 3% processing fee.
                  </Typography>
                </RadioGroup>
              </FormControl>
            </div>
          )
        );
      case "boolean":
        return (
          <div className={classes.switchField}>
            <CustomSwitch
              disabled={disabled}
              checked={sku[field.path]}
              onClick={() => handleEditSKU(sku, field.path, !sku[field.path])}
            />
            <Typography>{field.label}</Typography>
          </div>
        );
      default:
        return null;
    }
  }

  function getProductSKUFields(sku) {
    let advancedOpt = ADVANCED_PRICING_MODELS.find(
      (apm) => apm.value === sku.unit,
    );
    return (
      <>
        <div className={classes.row}>
          <FilledTextField
            label="Guest Price"
            value={sku.value ?? ""}
            placeholder="10"
            type="number"
            disabled={disabled}
            inputProps={{min: 0}}
            onChange={handlePriceChange(sku)}
            InputProps={{
              endAdornment:
                !!flags.customGapNightPrice &&
                !!advancedOpt &&
                !advancedOpt.name.includes("child") ? (
                  <InputAdornment style={{marginTop: 18}} position="end">
                    {"%"}
                  </InputAdornment>
                ) : (
                  selected.properties.currency && (
                    <InputAdornment style={{marginTop: 18}} position="end">
                      {selected.properties.currency}
                    </InputAdornment>
                  )
                ),
            }}
          />
          {!!flags.customGapNightPrice && sku.unit === nightlyRateOpt.value && (
            <FilledTextField
              label="Flat fee"
              value={sku.value2 ?? ""}
              placeholder="10"
              type="number"
              disabled={disabled}
              inputProps={{min: 0}}
              onChange={handleFlatFeeChange(sku)}
              InputProps={{
                endAdornment: selected.properties.currency && (
                  <InputAdornment style={{marginTop: 18}} position="end">
                    {selected.properties.currency}
                  </InputAdornment>
                ),
              }}
            />
          )}
          {sku.custom_pricing ? (
            <FilledTextField
              label="Unit"
              disabled={disabled}
              value={sku.unit || ""}
              onChange={(e) => handleEditSKU(sku, "unit", e.target.value)}
            />
          ) : (
            <FilledSelect
              label="Unit"
              value={sku.unit}
              disabled={disabled}
              onChange={(e) => handleEditPricingModel(sku, e.target.value)}
            >
              {PRICING_MODELS.map((k) => (
                <MenuItem value={k.value} key={`pricing-${k.value}`}>
                  {k.name}
                </MenuItem>
              ))}
              {!!flags.customGapNightPrice &&
                ADVANCED_PRICING_MODELS.map((k) => (
                  <MenuItem value={k.value} key={`pricing-${k.value}`}>
                    {k.name}
                  </MenuItem>
                ))}
            </FilledSelect>
          )}
          {upsell_spec?.fields &&
            upsell_spec.fields.map((f) => (
              <React.Fragment key={f.path}>{getField(f, sku)}</React.Fragment>
            ))}
        </div>
        <AccordionItem
          filled
          disableContentTopPadding
          description={
            expandedPayout === sku.sku_id
              ? "This payout breakdown outlines how the various commission partners affect your payout."
              : null
          }
          contentTopMargin={THEME.spacing.md}
          expanded={expandedPayout === sku.sku_id}
          title={"Payout Breakdown"}
          onClick={() =>
            setExpandedPayout((prev) =>
              prev === sku.sku_id ? null : sku.sku_id,
            )
          }
          content={
            <Payment
              hideSymbol
              sku={{
                ...sku,
                is_fee: !!selected.is_fee,
                currency: selected.properties.currency,
              }}
            />
          }
        />
      </>
    );
  }

  function ProductSKU(sku) {
    if (hasManySkus) {
      return (
        <React.Fragment key={sku.sku_id}>
          <AccordionItem
            outlined
            expanded={expanded === sku.sku_id}
            actions={
              !!multiple_skus &&
              !selected.is_fee &&
              !disabled && (
                <DeleteIconButton
                  disablePadding
                  onClick={() => handleRemoveSKU(sku)}
                />
              )
            }
            title={content.skus[sku.sku_id].name[selectedLang]}
            titlePlaceholder="Empty Name"
            onClick={() =>
              setExpanded((prev) => (prev === sku.sku_id ? null : sku.sku_id))
            }
            contentTopMargin={THEME.spacing.md}
            content={
              <>
                <FilledTextField
                  fullWidth
                  label="Name of option"
                  disabled={disabled}
                  placeholder="Option Name"
                  value={content.skus[sku.sku_id].name[selectedLang]}
                  onChange={handleNameChange(sku)}
                />
                {getProductSKUFields(sku)}
              </>
            }
          />
        </React.Fragment>
      );
    }
    return (
      <div className={classes.sku_card} key={sku.sku_id}>
        {getProductSKUFields(sku)}
      </div>
    );
  }

  const addLang = (lang) => {
    let newSkusObj = {...content.skus};
    let newSkuKeys = Object.keys(newSkusObj);
    _.each(newSkuKeys, (sk) => {
      newSkusObj[sk].name[lang] = "";
    });
    setContent((prev) => ({
      name: {...prev.name, [lang]: ""},
      desc: {...prev.desc, [lang]: ""},
      skus: newSkusObj,
    }));
    setLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const removeLang = (lang) => {
    const newLangs = langs.filter((l) => l !== lang);
    const newContent = {...content};
    delete newContent.name[lang];
    delete newContent.desc[lang];
    let newSkusObj = {...content.skus};
    let skuKeys = Object.keys(newSkusObj);
    _.each(skuKeys, (sk) => {
      delete newSkusObj[sk].name[lang];
    });
    newContent.skus = newSkusObj;
    setContent((prev) => newContent);
    setLangs((prev) => newLangs);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
  };

  function getDialogContent() {
    return (
      <>
        <div className={classes.section}>
          <ConnectedToButton
            listing={listing}
            isGroupView={isGroupView}
            listing_id={listing_id}
            group_id={group_id}
            data={selected}
            showSectionTitle
            type={selected.is_fee ? "fee" : "upsell"}
            disabled={disabled}
            editData={(newData) => editData(newData)}
            specificListings={selectedGroup?.connected_to?.ids}
            externalListings={externalListings}
            listingsOpen={listingsOpen}
            setListingsOpen={setListingsOpen}
          />
        </div>
        <Divider className={classes.divider} />
        <Conditions
          standalone
          disableEdit={disabled}
          title="Conditions"
          label={`Choose what conditions must be met in order to display this ${upsell_label} in the boarding pass.`}
          data={selected?.properties.conditions || []}
          setData={(c) => handleEditProduct("conditions", c)}
        />
        <Divider className={classes.divider} />
        <div className={clsx(classes.section, "-gap-md")}>
          <div className={classes.sectionTitle}>
            <SvgIcon
              className="icon"
              viewBox="0 0 16 17"
              component={DescriptionIcon}
            />
            <Typography className="title" variant="h1">
              {"Description"}
            </Typography>
          </div>
          <FilledTextField
            label={selected.is_fee ? "Fee name" : "Upsell name"}
            fullWidth
            characterLimit={characterLimit}
            characterLimitMarginTop={-THEME.spacing.md}
            disabled={disabled}
            value={content.name[selectedLang]}
            onChange={(e) => handleEditProduct("name", e.target.value)}
          />
          {!!prevSelected && !!selectedLang && (
            <EmailEditor
              outlined
              editorOnly
              useCustomFields
              lang={selectedLang}
              disabled={disabled}
              value={content.desc[selectedLang]}
              onChange={(e) => handleEditProduct("description", e)}
              placeholder="Description"
              imagePath={`${user_profile.enso_key}/upsells`}
              AIprops={{
                type: selected.is_fee
                  ? contentTypeEnum.fee
                  : contentTypeEnum.upsell,
                objects: {listing: AIListingData},
              }}
              onValidityCheck={handleContentValidity}
            />
          )}
        </div>
        <div
          className={clsx(classes.section, "-gap-md", {
            "-space-top": !selected.is_fee,
          })}
        >
          <div className={classes.sectionTitle}>
            <SvgIcon
              className="icon"
              viewBox="0 0 16 16"
              component={ListIcon}
            />
            <Typography
              className="title"
              variant="h1"
            >{`${capitalize(upsell_label)} options`}</Typography>
            {!selected.is_fee && !disabled && (
              <div className="actions">
                <PrimaryButton
                  size="small"
                  label={`Create ${upsell_label} option`}
                  onClick={() => handleAddSKU()}
                />
                <InfoText text={infoTexts.productOption} disableGutters />
              </div>
            )}
          </div>
          {!selected.is_fee && (
            <div className={classes.switchButton}>
              <CustomSwitch
                disabled={disabled}
                checked={selected.properties.manually_approve}
                onClick={() =>
                  handleEditProduct(
                    "manually_approve",
                    !selected.properties.manually_approve,
                  )
                }
              />
              <Typography>Manually approve purchase</Typography>
            </div>
          )}
          {!!skuKeys.length && skuKeys.map((skuK) => ProductSKU(skus[skuK]))}
        </div>
      </>
    );
  }

  function getPreviewContent() {
    return (
      <PreviewBPPanel
        type={selected.is_fee ? "fees" : "upgrades"}
        content={getUpdatedContent()}
        lang={selectedLang}
        listingId={isGroupView ? selectedGroup.connected_to.ids[0] : listing_id}
      />
    );
  }

  const languageSelector = (
    <LanguageSelector
      selectedLangs={langs}
      selectedLanguage={selectedLang}
      addLang={addLang}
      preventOverflow
      removeLang={removeLang}
      selectLang={setSelectedLang}
      light={!!headerImage && isUpsell}
    />
  );

  return (
    <CustomDialog
      open={open}
      fullWidth
      maxWidth="lg"
      customHeight={"calc(100% - 20px)"}
      disableEnforceFocus
      headerImageEditable={isUpsell}
      headerLight={!!headerImage && isUpsell}
      headerImageProps={{
        resourceType: "up",
        recommendedPictures: templateImages.up,
        pictures: listing?.listing_content?.pictures || [],
        onPictureChange: handleHeaderImgChange,
      }}
      headerAction={!disabled && languageSelector}
      headerClass={clsx({[classes.dialogHeader]: !!headerImage && isUpsell})}
      title={content.name[selectedLang] || content.name[defaultLang]}
      titleVariant="header"
      previewContent={getPreviewContent()}
      content={getDialogContent()}
      onClose={handleClose}
      labelConfirm={"Save"}
      transitionDuration={0}
      disableConfirm={disableSave}
      actionConfirm={handleSave}
      labelCancel={"Cancel"}
      actionCancel={handleClose}
    />
  );
};

export default withLDConsumer()(ProductModal);
