import {API} from "aws-amplify";

export const getBPPreview = async (body) => {
  try {
    const response = await API.post("ensoAPI", `/preview/keycard`, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getBPProductPreview = async (body) => {
  try {
    const response = await API.post("ensoAPI", `/preview/keycard/products`, body);
    return response;
  } catch (err) {
    throw err;
  }
};
