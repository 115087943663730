import React from "react";
import {Box, Button, Chip, TextField, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Close";
import ColumnsIcon from "@material-ui/icons/ViewColumnRounded";
import ExpandButton from "core/buttons/ExpandButton";
import SearchBar from "core/bars/SearchBar";
import {guestFilters} from "configuration/constants.js";
import {SelectedFilterList} from "components/Helpers/filterHelpers";
import PrimaryButton from "../../../../core/buttons/PrimaryButton";
import {useFlags} from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  crmButtonsContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingRight: "20px",
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: 2,
  },
  button: {
    marginRight: 10,
    "&:last-child": {
      marginRight: 0,
    },
  },
  chipLabel: {
    display: "flex",
    alignItems: "center",
  },
  searchBarContainer: {
    marginTop: 0,
    width: "260px",
    marginLeft: "14px",
    "& > .searchContainer": {paddingTop: 0},
  },

  filterChipContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "14px",
    marginTop: theme.spacing(1),
  },
}));

export default function GuestListTableHeader(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    filters,
    disableFilters,
    removeFilter,
    clearAll,
    setColumnsMenuAnchorEl,
    exportButton,
    setViewsDialogOpen,
    handleSearchInput,
    totals,
    searchBarProps,
    writeFilter,
    closeWriteFilter,
    onWriteFilterChange,
    onWriteFilterKeyDown,
    fieldParams,
  } = props;

  const flags = useFlags();
  const getFilterTags = () =>
    disableFilters ? null : (
      <SelectedFilterList outlined filters={filters} onDelete={removeFilter} />
    );

  return (
    <div className={classes.crmButtonsContainer}>
      <Box className={classes.topRow}>
        <div className={classes.searchBarContainer}>
          <SearchBar
            disableGutters
            noChipMargin
            handleSearchInput={handleSearchInput}
            preferredFilters={guestFilters.preferredFilters}
            placeholder={`Search ${totals.guests || ""} guests`}
            {...searchBarProps}
          />
        </div>
        <div className={classes.buttonGroup}>
          {flags.enableCrmViews && (
            <PrimaryButton
              rounded={true}
              color={"secondary"}
              label="Views"
              variant="contained"
              className={classes.button}
              sx={{height: "fit-content", padding: theme.spacing(1, 3)}}
              onClick={() => setViewsDialogOpen(true)}
            />
          )}
          <ExpandButton
            size="small"
            label="Columns"
            color="secondary"
            startIcon={<ColumnsIcon />}
            style={{height: "fit-content", padding: theme.spacing(1, 3)}}
            onClick={(e) => setColumnsMenuAnchorEl(e.currentTarget)}
            className={classes.button}
          />
          {!!exportButton &&
            React.cloneElement(exportButton, {className: classes.button})}
        </div>
      </Box>

      <Box className={classes.filterChipContainer}>
        {!!filters.length && getFilterTags()}
        {!!filters.length && (
          <Button variant="text" color="secondary" onClick={clearAll}>
            {"Clear all"}
          </Button>
        )}
        {!!writeFilter && (
          <Chip
            label={
              <>
                {fieldParams[writeFilter.id].name}:
                <TextField
                  autoFocus
                  placeholder="Type here"
                  value={writeFilter.value}
                  onChange={onWriteFilterChange}
                  onKeyDown={onWriteFilterKeyDown}
                  sx={{marginLeft: theme.spacing(1)}}
                />
              </>
            }
            onDelete={closeWriteFilter}
            deleteIcon={<DeleteIcon />}
            color="primary"
            variant="outlined"
            size="small"
            classes={{
              label: classes.chipLabel,
            }}
            sx={{
              "& .MuiChip-deleteIcon": {
                marginLeft: theme.spacing(1),
              },
            }}
          />
        )}
      </Box>
    </div>
  );
}
