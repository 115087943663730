import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Checkbox} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CustomCellElement from "components/Helpers/CustomCellElement";
import VirtualizedTable from "ui/base/table/VirtualizedTable";
import FilterMenus from "components/Dialogs/FilterMenus";
import {updateGuest} from "redux/actions/guestsActions";
import {guestFilters, integrations} from "configuration/constants.js";
import {fieldParams, metricsParams} from "configuration/specs.js";
import {getEncodedFilters, getNestedObject} from "utilities/helperFunctions";
import {isFirefox} from "react-device-detect";
import _ from "lodash";
import GuestListTableHeader from "./GuestListTableHeader";
import CrmColumnsMenu from "../panel/CrmColumnsMenu";
import {getCRMGuestList} from "../../../../redux/actions/crmActions";
import MuiSkeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  columnsMenu: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  columnsMenuItem: {
    padding: 0,
    borderRadius: 5,
    minHeight: 45,
  },
  tableSection: {
    flexGrow: 1,
    maxWidth: "calc(100vw - 100px)",
    overflowY: "hidden",
    overflowX: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  column: (props) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: props.isMoz ? "pre-wrap" : "nowrap",
    flex: 1,
  }),
}));

export default function GuestListTable({
  create,
  filters,
  updateFilters,
  exportButton,
  searchText,
  setSearchText,
  setViewsDialogOpen,
  hideCheckboxColumn,
  setSelectedIds,
  openGuestPanel,
  disableFilters,
}) {
  const classes = useStyles({isMoz: isFirefox});
  const dispatch = useDispatch();
  const customFieldsMap = React.useRef({});
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const totals = useSelector((state) => state.defaultReducer.totals);
  const [filtersAnchorEl, setFiltersAnchorEl] = React.useState(null);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [writeFilter, setWriteFilter] = React.useState(null);
  const [guestListHits, setGuestListHits] = React.useState([]);
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = React.useState(null);
  const [allColumns, setAllColumns] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([
    ...(metricsParams["guests"]?.table_fields || []),
  ]);
  const [guestListData, setGuestListData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const currentPageRef = React.useRef(currentPage);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const tableFields = metricsParams["guests"]?.table_fields || [];
  const hasFilters = !!metricsParams["guests"]?.filters.length;
  const rowCount = isLoading
    ? 20
    : searchText && searchText !== ""
      ? guestListHits.length
      : hasNextPage
        ? guestListHits.length + 1
        : guestListHits.length;

  const allFilters = React.useMemo(() => {
    let newFilters = [...guestFilters.filterKeys];
    if (groups.length < 2)
      newFilters = newFilters.filter((f) => f !== "guest_group_id");
    return newFilters;
  }, [groups]);

  const toggleSelectAll = (e) => {
    const val = e.target.checked;
    const newSelectedGuests = val
      ? [...guestListHits.map((g) => g.guest_id), "all"]
      : [];
    setSelectedGuests(newSelectedGuests);
    setSelectedIds(newSelectedGuests.filter((g) => g !== "all"));
  };

  const onRowCheck = (guestId) => {
    let newSelectedGuests;
    if (selectedGuests.includes("all")) {
      newSelectedGuests = selectedGuests.filter(
        (g) => !(g === guestId || g === "all"),
      );
    } else {
      newSelectedGuests = selectedGuests.includes(guestId)
        ? selectedGuests.filter((g) => g !== guestId)
        : [...selectedGuests, guestId];
    }
    setSelectedGuests(newSelectedGuests);
    setSelectedIds(newSelectedGuests.filter((g) => g !== "all"));
  };

  const removeFilter = (selectedFilter) => {
    const newFilters = [...filters];
    const filterIndex = filters.findIndex(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      updateFilters(newFilters);
    }
  };

  const searchBarProps = !disableFilters
    ? {
        enableFilters: true,
        filters,
        disableSelectedFilters: true,
        filterKeys: allFilters,
        handleDeleteFilter: removeFilter,
        setFilters: updateFilters, // Use updateFilters here
      }
    : {};

  const columns = React.useMemo(() => {
    const newColumns = [];
    if (!hideCheckboxColumn) {
      newColumns.push({
        width: 1,
        label: (
          <Checkbox
            size="small"
            color="secondary"
            checked={selectedGuests.includes("all")}
            onChange={toggleSelectAll}
            style={{marginLeft: "4px"}}
          />
        ),
        field: "checkbox",
        dataKey: "checkbox",
        flexGrow: 0.5,
        customCell: true,
      });
    }
    newColumns.push({
      width: 1,
      label: "dot",
      field: "dot",
      dataKey: "dot",
      flexGrow: 0.2,
      customCell: true,
      hideLabel: true,
    });
    _.each(tableFields, (field) => {
      if (!selectedColumns.includes(field)) return;
      newColumns.push({
        field,
        width: 1,
        label: fieldParams[field].name,
        dataKey: fieldParams[field].path.slice(1).join("."),
        customCell: true,
        flexGrow: 1,
        numeric: false,
        sort: true,
      });
    });
    if (
      guestListData?.length &&
      guestListData[0]?.hits?.length &&
      guestListData[0]?.hits[0]?.custom_fields
    ) {
      Object.keys(customFieldsMap.current).forEach((key, i) => {
        if (!selectedColumns.includes(key)) return;
        newColumns.push({
          field: `custom_field_${i}`,
          width: 1,
          label: fieldParams[key]?.name || key,
          dataKey: customFieldsMap.current[key],
          flexGrow: 1,
          customCell: true,
          sort: true,
          numeric: false,
        });
      });
    }
    return newColumns;
  }, [guestListHits, selectedGuests, selectedColumns, hideCheckboxColumn]);

  React.useEffect(() => {
    setSelectedGuests([]);
  }, [filters]);

  React.useEffect(() => {
    setGuestListHits((prev) =>
      prev.map((g) => (guests[g.guest_id] ? {...g, ...guests[g.guest_id]} : g)),
    );
  }, [guests]);

  React.useEffect(() => {
    setIsLoading(true);
    const newHits = guestListData?.length ? guestListData[0].hits : [];
    setGuestListHits(newHits);
    let newCustomFieldsMap = {};
    if (
      guestListData?.length &&
      guestListData[0]?.hits?.length &&
      guestListData[0]?.hits[0]?.custom_fields
    ) {
      guestListData[0].hits.forEach((hit) => {
        if (hit.custom_fields) {
          Object.keys(hit.custom_fields).forEach((key) => {
            newCustomFieldsMap[key] = `custom_fields.${key}`;
          });
        }
      });
    }
    customFieldsMap.current = newCustomFieldsMap;
    setAllColumns([
      ...new Set([...tableFields, ...Object.keys(newCustomFieldsMap)]),
    ]);
  }, [guestListData, create]);

  React.useEffect(() => setSelectedColumns([...tableFields]), []);
  React.useEffect(() => {
    if (hideCheckboxColumn && selectedGuests.length) setSelectedGuests([]);
  }, [hideCheckboxColumn]);
  React.useEffect(() => {
    setGuestListHits([]);
    setCurrentPage(-1);
    currentPageRef.current = -1;
    setHasNextPage(true);
    setIsLoading(true);
    dispatch(
      getCRMGuestList(
        null,
        "line_chart",
        null,
        getEncodedFilters(filters, false),
        searchText,
        (newData) => {
          setGuestListHits(newData[0]?.hits || []);
          setGuestListData((prev) => newData);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        0,
      ),
    );
  }, [filters, searchText]);

  const isRowLoaded = ({index}) => {
    if (searchText && searchText !== "") return true;
    return index < guestListHits.length;
  };

  const clearAll = () => {
    setSearchText("");
    setSelectedGuests([]);
    updateFilters([]);
  };

  const viewProfile = (index) => {
    const selectedGuest = guestListHits[index];
    if (selectedGuest?.unread_messages) {
      dispatch(
        updateGuest({
          guest_id: selectedGuest.guest_id,
          field: "unread_messages",
          val: false,
          disableAlert: true,
        }),
      );
    }
    openGuestPanel(selectedGuest);
  };

  const onFilterSelect = (selectedFilter) => {
    const existingFilter = filters.find(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (!existingFilter) {
      if (!selectedFilter.value) setWriteFilter({...selectedFilter, value: ""});
      else updateFilters([...filters, selectedFilter]);
    }
    setFiltersAnchorEl(null);
  };

  const saveWriteFilter = () => {
    updateFilters([...filters, writeFilter]);
    setWriteFilter(null);
  };

  const onWriteFilterKeyDown = (event) => {
    if (event.key === "Escape") {
      setWriteFilter(null);
      return false;
    } else if (!["Enter", "Tab"].includes(event.key)) {
      return false;
    }
    saveWriteFilter();
  };

  const onWriteFilterChange = (e) => {
    const val = e.target.value;
    setWriteFilter((prev) => ({...prev, value: val}));
  };

  const getCustomCell = (columnConfig, cellData, rowIndex) => {
    if (rowIndex >= guestListHits.length) {
      return (
        <div className={classes.column}>
          <MuiSkeleton animation="wave" width="100%" height={34} />
        </div>
      );
    }

    const columnDataKey = columnConfig.dataKey;
    const cellValue = columnDataKey.includes(".")
      ? getNestedObject(guestListHits[rowIndex], columnDataKey.split("."))
      : cellData;
    const field = columnDataKey.includes(".")
      ? columnDataKey.split(".").pop()
      : columnConfig.field;
    const obj = columnDataKey.includes(".")
      ? {...guestListHits[rowIndex], [field]: cellValue}
      : guestListHits[rowIndex];

    return (
      <div className={classes.column}>
        <CustomCellElement
          data={cellValue}
          customClass={
            columnConfig.field === "checkbox"
              ? `guest-checkbox-${rowIndex}`
              : null
          }
          rowIndex={rowIndex}
          obj={obj}
          field={field}
          simpleBkgDate={columnDataKey.includes(".")}
          selectedValues={selectedGuests}
          viewProfile={viewProfile}
          onCheck={columnConfig.dataKey === "checkbox" ? onRowCheck : null}
        />
      </div>
    );
  };

  const rowGetter = ({index}) => {
    if (index >= guestListHits.length) {
      return {};
    }
    const data = guestListHits[index];
    return {
      ...data,
      integration: integrations[data.integration],
      name: data.name || "Unknown",
      email: data.email,
      checkbox: data.guest_id,
    };
  };

  const loadNextPage = async () => {
    if (currentPageRef.current === -1 || currentPage === -1) {
      currentPageRef.current = 2;
      setCurrentPage(2);
      return;
    }

    if (loadingMore || !hasNextPage || (searchText && searchText !== ""))
      return;
    setLoadingMore(true);

    await dispatch(
      getCRMGuestList(
        null,
        "line_chart",
        null,
        getEncodedFilters(filters, false),
        searchText,
        (newData) => {
          if (newData[0]?.hits?.length) {
            setGuestListHits((prev) => [...prev, ...newData[0].hits]);
            setCurrentPage((prevPage) => {
              const newPage = prevPage + 1;
              currentPageRef.current = newPage;
              return newPage;
            });

            if (newData[0].hits.length < 50) {
              setHasNextPage(false);
            }
          } else {
            setHasNextPage(false);
          }
          setLoadingMore(false);
        },
        () => {
          setLoadingMore(false);
          setHasNextPage(false);
        },
        currentPageRef.current,
      ),
    );
  };

  return (
    <div className={classes.root}>
      <CrmColumnsMenu
        allColumns={allColumns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        fieldParams={fieldParams}
        columnsMenuAnchorEl={columnsMenuAnchorEl}
        closeColumnsMenu={() => {
          setColumnsMenuAnchorEl(null);
        }}
      />
      <FilterMenus
        filterList={hasFilters ? metricsParams["guests"].filters : []}
        anchorEl={filtersAnchorEl}
        onClose={() => {
          setFiltersAnchorEl(null);
        }}
        onFilterSelect={onFilterSelect}
      />
      <GuestListTableHeader
        filters={filters}
        setViewsDialogOpen={setViewsDialogOpen}
        disableFilters={disableFilters}
        removeFilter={removeFilter}
        clearAll={clearAll}
        setColumnsMenuAnchorEl={setColumnsMenuAnchorEl}
        exportButton={exportButton}
        searchText={searchText}
        handleSearchInput={(val) => setSearchText(val)}
        totals={totals}
        searchBarProps={searchBarProps}
        writeFilter={writeFilter}
        closeWriteFilter={() => {
          setWriteFilter(null);
        }}
        onWriteFilterChange={onWriteFilterChange}
        onWriteFilterKeyDown={onWriteFilterKeyDown}
        fieldParams={fieldParams}
      />
      <div className={classes.tableSection}>
        {!guestListHits.length && !isLoading ? (
          <VirtualizedTable
            rowCount={0}
            columns={columns}
            rowHeight={56}
            rowGetter={rowGetter}
            getCustomCell={getCustomCell}
            disableAutoHeaderHeight
          />
        ) : (
          <Box height={"100%"} minWidth={columns.length * 150}>
            <VirtualizedTable
              rowCount={rowCount}
              loaderRowCount={rowCount}
              rowGetter={rowGetter}
              columns={columns}
              onRowClick={({index, event}) => {
                if (index >= guestListHits.length) return;
                if (event.clientX > 138 && hideCheckboxColumn) {
                  viewProfile(index);
                } else if (event.clientX > 138) {
                  onRowCheck(guestListHits[index].guest_id);
                }
              }}
              rowHeight={56}
              getCustomCell={getCustomCell}
              useInfiniteLoader={!(searchText && searchText !== "")}
              isRowLoaded={isRowLoaded}
              loadMoreRows={
                !(searchText && searchText !== "") ? loadNextPage : undefined
              }
              disableAutoHeaderHeight
            />
          </Box>
        )}
      </div>
    </div>
  );
}
