import React from "react";
import {
  Grid,
  Paper,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {THEME} from "configuration/settings";
import IconCard from "../../../base/card/IconCard";
import {guestViewsUrlMap} from "../../../../configuration/specs";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 10,
    maxWidth: "60%",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)",
    maxHeight: "calc(100% - 100px)",
    minWidth: "1000px",
    backgroundColor: THEME.white,
    overflowY: "auto",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
  titleText: {
    padding: THEME.spacing.lg,
    fontWeight: 600,
    fontSize: 18,
  },
  dialogContent: {
    padding: THEME.spacing.xl,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: THEME.subdued,
  },
}));

const CrmViewsDialog = ({onClose}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = (queryString) => {
    const baseUrl = window.location.pathname;
    history.push(`${baseUrl}${queryString}`);
    onClose();
  };

  return (
    <Paper className={classes.paper}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.titleText}>Guest Views</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" className={classes.subtitle}>
          Select a filtered view of your guest list:
        </Typography>
        <Grid container spacing={3}>
          {Object.entries(guestViewsUrlMap).map(([key, view]) => (
            <Grid item xs={4} key={key}>
              <IconCard
                icon={view.icon}
                title={view.title}
                description={view.description}
                onClick={() => handleRedirect(view.redirect_url)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Paper>
  );
};

export default CrmViewsDialog;
